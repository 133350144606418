<template>
    <div class="LookMaterialsPost">
        <el-page-header @back="$router.go(-1)" content="我在找料发帖"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/IndustryTrendsList' }">我在找料</el-breadcrumb-item>
                <el-breadcrumb-item>发布</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="f-c-c">
            <div class="boxShadow borderRa-10 padding-70 postBox">
                <div class="title mb-60">发布求购</div>
                <div class="f-cl-c">
                    <el-form class="f-cl-c-c w--100" ref="ruleForm" :model="ruleForm" label-width="110px"
                        :rules="LookMaterialsPostResume">
                        <el-form-item label="产品类型" prop="productType" key="productType">
                            <div class="f-w">
                                <div v-for="item in options" :key="item.value" class="productTypeItem"
                                    :class="item.select ? 'selectY' : 'selectN'"
                                    @click="item.select ? NoproductTypeChange(item.value) : productTypeChange(item.value)">
                                    {{ item.label }}
                                </div>
                            </div>
                            <div class="flex">
                                <div>
                                    类型说明：
                                </div>
                                <div>
                                    <div v-for="(item, index) in options" :key="index">
                                        <div class="typeDescribe" v-if="item.select">{{ item.typeDescribe }}</div>

                                    </div>
                                </div>

                            </div>
                        </el-form-item>
                        <el-form-item class="w--60" label="求购名称" prop="postHead">
                            <el-input style="width: 500px;" maxlength="30" type="text" v-model="ruleForm.postHead"
                                placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item class="w--60" label="求购内容" prop="postContent">
                            <el-input style="width: 500px;" type="textarea" v-model="ruleForm.postContent"
                                placeholder="请输入内容" maxlength="1500" clearable :show-word-limit="true"></el-input>
                        </el-form-item>
                        <el-form-item class="w--60" label="联系人" prop="contact">
                            <el-input style="width: 500px;" maxlength="30" type="text" v-model="ruleForm.contact"
                                placeholder="请输入联系人"></el-input>
                        </el-form-item>
                        <el-form-item class="w--60" label="联系方式" prop="contactValue">
                            <div class="flex">
                                <el-select style="width: 100px;" v-model="ruleForm.contactType">
                                    <el-option v-for="item in contactTypeList" :key="item.name" :label="item.name"
                                        :value="item.value">
                                        <div class="f-c-b">
                                            <img v-if="item.name == '手机'" src="../../../assets/Community/iPhoneIcon.png"
                                                alt="">
                                            <img v-else-if="item.name == '微信'"
                                                src="../../../assets/Community/WeChatIcon.png" alt="">
                                            <img v-else-if="item.name == 'QQ'"
                                                src="../../../assets/Community/QQIcon.png" alt="">
                                            <img v-else-if="item.name == '邮箱'"
                                                src="../../../assets/Community/MailboxIcon.png" alt="">
                                            <span>{{ item.name }}</span>
                                        </div>

                                    </el-option>
                                </el-select>
                                <el-input class="ml-20" style="width: 220px;"
                                    v-model="ruleForm.contactValue"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item class="w--60" label="有效期" prop="expirationDays">
                            <el-select style="width: 100px;" v-model="ruleForm.expirationDays">
                                <el-option v-for="item in expirationDaysList" :key="item.name" :label="item.name"
                                    :value="item.value">
                                    <div class="f-c-b">
                                        <span>{{ item.name }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="w--60" label="图片" prop="imgList">
                            <UploadImages @FormData="FormData" :imageUrl="ruleForm.permit"
                                :imageUrlList="ruleForm.mobjectArray" :disabled="disabledChange"
                                @deleteUploadImage="deleteUploadImage">
                            </UploadImages>
                        </el-form-item>
                    </el-form>
                    <div class="mt-60 f-c-c">
                        <div class="f-c-c Btn1 mr-40" @click="addForumBuy(2)">保存</div>
                        <div class="f-c-c Btn2" @click="addForumBuy(1)">发布</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { LookMaterialsPostResume } from "@/utils/FormRules";

export default {
    name: 'LookMaterialsPost',
    components: {
        UploadImages: () => import("../components/UploadImages.vue")
    },
    data() {
        return {
            options: [],
            typeDescribeList: [],
            LookMaterialsPostResume,  // 规则
            disabledChange: false,
            ruleForm: {
                postHead: '',  // 帖子标题
                postContent: '',  // 帖子内容
                contact: '',  // 联系人
                contactType: 'P',  // 联系方式
                contactValue: '', // 联系方式值
                expirationDays: '30',  // 有效期
                productType: [],
                permit: '',
                mobjectArray: []
            },
            contactTypeList: [
                {
                    value: 'P',
                    name: '手机',
                    imgPath: '../../../assets/Community/iPhoneIcon.png'
                }, {
                    value: 'W',
                    name: '微信',
                    imgPath: '@/assets/Community/WeChatIcon.png'
                }, {
                    value: 'Q',
                    name: 'QQ',
                    imgPath: '@/assets/Community/QQIcon.png'
                }, {
                    value: 'M',
                    name: '邮箱',
                    imgPath: '@/assets/Community/MailboxIcon.png'
                }
            ],
            expirationDaysList: [
                {
                    value: 7,
                    name: '7天'
                }, {
                    value: 14,
                    name: '14天'
                }, {
                    value: 30,
                    name: '30天'
                }, {
                    value: 60,
                    name: '60天'
                }, {
                    value: 90,
                    name: '90天'
                }
            ],
        }
    },
    created() {
        this.findSearchProductTypeList()
    },
    methods: {
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {},
                        options = [];
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName,
                            typeDescribe: item.typeDescribe,
                            select: false,
                        }
                        options.push(odj);
                    });
                    options.shift(); //去除第一个
                    this.options = options;
                }
            })
        },
        productTypeChange(value) {
            let { productType } = this.ruleForm,
                that = this,
                { options } = that;
            if (productType.length < 3) {
                options.forEach(item => {
                    if (value == item.value && !item.select) {
                        item.select = true;
                        productType.push(value);
                    }
                })
            }
            that.options = options;
            that.ruleForm.productType = productType;
        },
        NoproductTypeChange(value) {
            let { productType } = this.ruleForm,
                that = this,
                { options } = that;
            options.forEach(item => {
                if (value == item.value && item.select) {
                    item.select = false;
                    productType = productType.filter(item => item !== value);
                }
            })
            that.options = options;
            that.ruleForm.productType = productType;

        },
        FormData(params) {
            let that = this;
            var formData = new FormData();
            formData.append("userId", parseInt(that.$store.state.userId));
            formData.append("token", that.$getStorage("token"));
            formData.append("file", params.file);
            formData.append("fileNameState", '');
            that.uploadForumOssFile(formData);
        },
        uploadForumOssFile(formData) {
            let that = this;
            that.$http.uploadForumOssFile(formData).then(res => {
                that.$common.message(res.msg, "success");
                let { data } = res;
                that.ruleForm.permit = data;
                that.ruleForm.mobjectArray.push(data);
            })
        },
        addForumBuy(saveState) {
            let that = this,
                ruleFormValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid) {
                let { fcommentLocation, sourceIp } = that.$getStorage('getIpAddress'),
                    { mobjectArray, postContent, postHead, contact, contactType, contactValue, productType, expirationDays } = that.ruleForm,
                    mobject1 = mobjectArray[0],
                    mobject2 = mobjectArray[1],
                    mobject3 = mobjectArray[2],
                    mobject4 = mobjectArray[3],
                    mobject5 = mobjectArray[4],
                    data = {
                        postUserId: parseInt(that.$store.state.userId),
                        saveState,
                        previewContent: postContent.slice(0, 100),
                        mobject1,
                        mobject2,
                        mobject3,
                        mobject4,
                        mobject5,
                        contact,
                        contactType,
                        contactValue,
                        postContent,
                        postHead,
                        productType: productType.join(','),
                        postLocation: fcommentLocation,
                        expirationDays,
                        sourceIp,
                    }
                that.$confirm('确定发布？', '提示').then(() => {
                    that.$http.addForumBuy(data).then(res => {
                        that.$common.message(res.msg, "success");
                        that.$router.go(-1);
                    })
                }).catch(() => { })
            }
        },
        // 删除上传的图片
        deleteUploadImage(idx) {
            let that = this,
                { mobjectArray } = that.ruleForm;
            mobjectArray.splice(idx, 1)
        },

    },
}

</script>
<style lang='less' scoped>
.LookMaterialsPost {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .postBox {
        width: 1040px;

        // height: 520px;
        .productTypeItem {
            width: 80px;
            height: 40px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .selectY {
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            color: #FFF;
        }

        .selectN {
            border: 1px solid #333333;
            color: #333
        }

        .typeDescribe {
            width: 550px;
            height: 30px;
            background: url('../../../assets/supply/bg_half_blue.png');
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 550px 10px;
            // background-position: 0px 2px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
        }

        .title {
            font-weight: 500;
            font-size: 36px;
            color: #000000;
        }

        .Btn1 {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }

        .Btn2 {
            width: 160px;
            height: 50px;
            background: #EFF4FA;
            border-radius: 10px;
            color: #0363FA;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>